import React, { useState } from 'react'

import { ChangePasswordContent } from '../../components/auth/ChangePasswordContent'
import { Layout } from '../../components/auth/Layout'
import { AccountType } from '../../requests'

const SubjectsChangePasswordPage = () => {
  const [hasCustomTranslations, setHasCustomTranslations] = useState(false)
  return (
    <Layout accountType={AccountType.Subject} hasCustomTranslations={hasCustomTranslations}>
      <ChangePasswordContent accountType={AccountType.Subject} setHasCustomTranslations={setHasCustomTranslations} />
    </Layout>
  )
}

export default SubjectsChangePasswordPage
export const Head = () => <title>Datacapt - Change Password</title>
