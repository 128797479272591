import './ChangePasswordForm.less'

import { Form, Input } from 'antd'
import React from 'react'

import { useScopedIntl, useSubmit } from '../../../hooks'
import { AccountType, CustomConsent } from '../../../requests'
import { validateRequired } from '../../../validation'
import { DatacFormItem } from '../../common'
import { FormInputWrapper } from '../FormInputWrapper'
import { FormSubmitButton } from '../FormSubmitButton'
import { PasswordTooltip } from '../PasswordTooltip'
import { CustomTermsControl, TermsControl } from '../SignUpForm'
import { PasswordRejectionError, validatePassword } from '../validatePassword'

export interface ChangePasswordFormData {
  password: string
  passwordConfirmation: string
  currentPassword?: string
}

interface Props {
  onSubmit: (data: ChangePasswordFormData) => Promise<void>
  onChange: () => void
  passwordRejectionError: PasswordRejectionError
  isWrongCredentials: boolean
  isOldPasswordNeeded: boolean
  isLastAttempt: boolean
  minPasswordLength: number
  consents: CustomConsent[]
  isConsentNeeded: boolean
}

export const ChangePasswordForm: React.FC<Props> = ({
  onSubmit,
  onChange,
  passwordRejectionError,
  isWrongCredentials,
  isOldPasswordNeeded,
  isLastAttempt,
  minPasswordLength,
  consents,
  isConsentNeeded
}) => {
  const intl = useScopedIntl('')
  const intlField = useScopedIntl('auth.signup.field')
  const [isSubmitOngoing, onFormSubmit] = useSubmit<ChangePasswordFormData>(onSubmit)

  const getPasswordRejectionErrorLabel = () => {
    switch (passwordRejectionError) {
      case PasswordRejectionError.TooCommon:
        return intlField('password.validation.too_common')
      case PasswordRejectionError.TooSimilar:
        return intlField('password.validation.too_similar')
      case PasswordRejectionError.TheSameAsCurrent:
        return intlField('password.validation.same_as_current')
      default:
        return undefined
    }
  }

  const getWrongCredentialErrorLabel = [
    `${isWrongCredentials ? intlField('validation.wrong_credentials') : ''}`,
    `${isLastAttempt ? intlField('validation.one_attempt_left') : ''}`
  ]
    .join(' ')
    .trim()

  return (
    <div className="change-password-form">
      <Form
        name="changePasswordForm"
        onFinish={data => onFormSubmit(data as ChangePasswordFormData)}
        onValuesChange={onChange}
      >
        {isOldPasswordNeeded && (
          <FormInputWrapper label={intlField('current_password.label')}>
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: intlField('password.validation.required')
                }
              ]}
              validateStatus={isWrongCredentials ? 'error' : undefined}
              help={getWrongCredentialErrorLabel}
            >
              <Input.Password
                className="auth-form-input"
                size="large"
                type="password"
                placeholder={intlField('current_password.placeholder')}
              />
            </Form.Item>
          </FormInputWrapper>
        )}

        <FormInputWrapper label={intlField('new_password.label')}>
          <PasswordTooltip minPasswordLength={minPasswordLength}>
            <Form.Item
              name="password"
              rules={[
                {
                  validator: (_, value) =>
                    validatePassword(value, minPasswordLength).catch(messageId =>
                      Promise.reject(intl(messageId, { length: minPasswordLength }))
                    )
                }
              ]}
              validateStatus={passwordRejectionError ? 'error' : undefined}
              help={getPasswordRejectionErrorLabel()}
            >
              <Input.Password
                className="auth-form-input"
                size="large"
                type="password"
                placeholder={intlField('new_password.placeholder')}
              />
            </Form.Item>
          </PasswordTooltip>
        </FormInputWrapper>

        <FormInputWrapper label={intlField('password_confirmation.label')}>
          <Form.Item
            name="passwordConfirmation"
            dependencies={['password']}
            validateFirst
            rules={[
              {
                required: true,
                message: intlField('password_confirmation.validation.required')
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject(intlField('password_confirmation.validation.same'))
                }
              })
            ]}
            validateStatus={passwordRejectionError ? 'error' : undefined}
            help={getPasswordRejectionErrorLabel()}
          >
            <Input.Password
              className="auth-form-input"
              size="large"
              type="password"
              placeholder={intlField('password_confirmation.placeholder')}
            />
          </Form.Item>
        </FormInputWrapper>

        {isConsentNeeded &&
          (consents?.length > 0 ? (
            consents.map(consent => (
              <DatacFormItem
                key={consent.id}
                className="change-password-form__consent-input"
                name={`consent-${consent.id}`}
                validate={validateRequired(intl('common.required'))}
              >
                <CustomTermsControl consent={consent} />
              </DatacFormItem>
            ))
          ) : (
            <DatacFormItem name="terms" validate={validateRequired(intl('common.required'))}>
              <TermsControl accountType={AccountType.Subject} />
            </DatacFormItem>
          ))}
        <Form.Item>
          <FormSubmitButton
            className="change-password-form__submit"
            label={intl('auth.change_password.submit')}
            loading={isSubmitOngoing}
          />
        </Form.Item>
      </Form>
    </div>
  )
}
